// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import brands from '@src/views/manage/brands/store/reducer'
import agencies from '@src/views/manage/show-rooms/store/reducer'
import categories from '@src/views/manage/categories/store/reducer'
import subcategories from '@src/views/manage/subcategories/store/reducer'
import subcategories2 from '@src/views/manage/sub-subcategories/store/reducer'
import banners from '@src/views/manage/banners/store/reducer'
import sav from '@src/views/manage/sav/store/reducer'
import newsletters from '@src/views/manage/newsletters/store/reducer'
import collaborators from '@src/views/manage/user/collaborators/store/reducer'
import clients from '@src/views/manage/user/clients/store/reducer'
import partners from '@src/views/manage/user/partners/store/reducer'
import products from '@src/views/services/products/store/reducer'
import orders from '@src/views/services/orders/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  brands,
  agencies,
  categories,
  subcategories,
  subcategories2,
  banners,
  collaborators,
  clients,
  partners,
  products,
  sav,
  newsletters,
  orders
})

export default rootReducer
